import React from "react";
const VideoModal = ({ isOpen, onClose, videoSrc }) => {
  if (!isOpen) return null; // Don't render if modal is closed
  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
      onClick={onClose} // Close modal when clicking on the backdrop
    >
      <div
        className="relative bg-black rounded-lg shadow-lg p-8 w-[90%] md:w-2/3 max-w-full h-[50vh] md:h-[78vh]"
        onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the modal
      >
        {/* Close Button */}
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-white hover:text-slate-300 text-[20px]"
        >
          ✖
        </button>
        {/* Video */}
        <div className="relative w-full h-full">
          <iframe
            className="absolute inset-0 w-full h-full"
            src={videoSrc}
            title="Demo Video"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </div>
  );
};
export default VideoModal;
