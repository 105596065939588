'use client';
import React, { useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    TextField,
    IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import axios from 'axios';
import toast from 'react-hot-toast';
const ScheduleDemoForm: React.FC<{ open: boolean; onClose: () => void }> = ({ open, onClose }) => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        dateTime: '',
        organization: '',
        phone: '',
    });
    const [errors, setErrors] = useState({
        name: false,
        email: false,
        dateTime: false,
    });
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };
    const clearForm = () => {
        setFormData({
            name: '',
            email: '',
            dateTime: '',
            organization: '',
            phone: '',
        });
        setErrors({
            name: false,
            email: false,
            dateTime: false,
        });
    };
    const handleSubmit = async () => {
        const { name, email, dateTime } = formData;
        // Validation logic
        const newErrors = {
            name: name.trim() === '',
            email: email.trim() === '' || !/\S+@\S+\.\S+/.test(email), // Simple email validation
            dateTime: dateTime.trim() === '',
        };
        setErrors(newErrors);
        if (!newErrors.name && !newErrors.email && !newErrors.dateTime) {
            try {
                const apiEndpoint = '/api/schedule-demo'; // Hardcoded API endpoint
                await axios.post(apiEndpoint, formData);
                toast.success('Form submitted successfully!');
                clearForm();
                onClose(); // Close the popup after successful submission
            } catch (error) {
                console.error('Error submitting form:', error);
                toast.error('Failed to submit form. Please try again.');
            }
        } else {
            toast.error('Please fill all required fields correctly.');
        }
    };
    const handleClose = () => {
        clearForm();
        onClose();
    };
    return (
        <Dialog open={open} onClose={handleClose}>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                style={{ position: 'absolute', right: 8, top: 8 }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent className="!pt-8">
                {/* Name Field */}
                <TextField
                    required
                    fullWidth
                    label="Name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    error={errors.name}
                    helperText={errors.name ? 'Name is required' : ''}
                    className="!mb-4"
                />
                {/* Email Field */}
                <TextField
                    required
                    fullWidth
                    label="Email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    error={errors.email}
                    helperText={errors.email ? 'Enter a valid email' : ''}
                    className="!mb-4"
                />
                                {/* Phone Field (Optional) */}
                                <TextField
                    fullWidth
                    label="Phone"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    className="!mb-4"
                />
                                {/* Organization Field (Optional) */}
                                <TextField
                    fullWidth
                    label="Organization"
                    name="organization"
                    value={formData.organization}
                    onChange={handleChange}
                    className="!mb-4"
                />
                {/* Date and Time Field */}
                <TextField
                    required
                    fullWidth
                    type="datetime-local"
                    label="Date and Time"
                    name="dateTime"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    value={formData.dateTime}
                    onChange={handleChange}
                    error={errors.dateTime}
                    helperText={errors.dateTime ? 'Date and time are required' : ''}
                    className="!mb-4"
                />
            </DialogContent>
            <DialogActions className="mb-4">
                <Button
                    onClick={handleSubmit}
                    className="!px-12 !py-3 !bg-[#1bb560] !capitalize !text-white !text-lg !font-medium  !rounded-full"
                >
                    Submit
                </Button>
                <Button
                    onClick={handleClose}
                    className="!px-12 !py-3 !bg-[#98a2b3] !capitalize !text-white !text-lg !font-medium !rounded-full"
                >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};
export default ScheduleDemoForm;