import React from 'react'
import { useRouter } from 'next/navigation';

const Footer = () => {
    const router = useRouter();
  return (
    <div className="w-full bg-[#f2f2f2] py-8">
    <div className="max-w-screen-xl mx-auto px-4 md:px-8 lg:px-0">
        {/* Info Section */}
        <div className="text-xs scale-90 sm:scale-100 sm:text-base text-[#4d4d4d] font-medium leading-[30px]">
            Navigate our site to learn more about what Botafy can do for you. Access important information, review our policies, and connect with us on social media to stay updated on the latest AI innovations and company news.
        </div>
        <div className='flex flex-col sm:flex-row-reverse items-center justify-center w-full'>
            {/* Social Icons */}
            <div className="mt-4 hidden justify-center items-center gap-2 lg:justify-start">
                <img alt="facebook" src="/facebook.svg" className="w-auto h-auto cursor-not-allowed" />
                <img alt="instagram" src="/instagram.svg" className="w-auto h-auto cursor-not-allowed" />
                <img alt="twitter" src="/twitter.svg" className="w-auto h-auto cursor-not-allowed" />
                <img alt="linked_in" src="/linked_in.svg" className="w-auto h-auto cursor-not-allowed" />
            </div>
            {/* Navigation Links */}
            <div className="flex sm:flex-row justify-center gap-3 items-center sm:self-center">
                    <div className="cursor-pointer text-xs scale-90 sm:scale-100 sm:text-base text-[#4d4d4d] font-semibold underline" onClick={() => { router.push('/terms-and-conditions') }}>
                        Terms and Conditions
                    </div>
                    <div className="cursor-pointer text-xs scale-90 sm:scale-100 sm:text-base text-[#4d4d4d] font-semibold underline" onClick={() => { router.push('/privacy-policy') }}>
                        Privacy Policy
                    </div>
                </div>
            </div>
        </div>
</div>
  )
}

export default Footer
